<template>
  <span>
    <v-icon class="saving" :style="{'opacity':show?1:0}" :color="color">{{ icon }}</v-icon>
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {return {
    icon: 'mdi-content-save',
    color: 'white',
    timer: null,
    show: false,
  }},
  computed: {
  },
  methods: {
    saving() {
      this.icon = 'mdi-content-save';
      this.color = 'primary';
      this.show = true;
    },
    success() {
      this.icon = 'mdi-content-save-check';
      this.color = 'green accent-4';
      this.show = true;
      this.timer = setTimeout(()=>{ this.show = false; }, 2000)
    },
    error() {
      this.icon = 'mdi-content-save-alert';
      this.color = 'red';
    },
    event(what) {
      if(what == 'saving') {
        this.saving();
      } else if(what == 'success') {
        this.success();
      } else {
        this.error();
      }
    }
  }
});
</script>

<style scoped>
.saving {
  animation: 0.5s ease-out;
}
</style>
 
